<template>
    <div class="zt-page-content">
        <div class="flex flex-v" style="height:100%;overflow:hidden;">
            <div style="text-align:right;padding:20px;">
                <el-button @click="goto('/desgin/fchz/submit')" size="small" type="primary">提交新设计</el-button>
            </div>
            <div class="list flex-1" ref="load" style="overflow-y:auto;">
                <div v-for="(item,idx) in items" :key="idx" class="zt-block list-item">
                    <div class="list-item-top flex">
                        <div @click="goto('fchz/detail/'+item.id)" style="width:220px;height:220px;margin-right:40px;">
                            <div v-if="item.photo_render && item.photo_render.length>0" class="bg_img"
                            :style="{backgroundImage: 'url(' + imgUrl+item.photo_render[0]+'!width_250px' + ')'}"></div>
                        </div>
                        <div class="lit-middle flex-1">
                            <div v-if="item.set && item.set.name" class="serie-name">{{item.set.name}}</div>
                            <h3 @click="goto('fchz/detail/'+item.id)" v-if="item.name" class="item-name ellipsis1">{{item.name}}</h3>
                            <div>
                                <span style="margin-right:22px" v-if="item.cat">{{item.cat.name}}</span>
                                <span v-if="item.style">{{item.style.name}}</span>
                            </div>
                            <div class="item-type">
                                <div class="flex">
                                    <span v-if="item.stat==1" style="color:#2BBAAE;">
                                        <i style="font-size:17px;" class="iconfont icon-xuanzhongduihao1"></i>已上架</span>
                                    <span v-if="item.stat==2" style="color:#F66F6A;">
                                        <i style="font-size:17px;" class="iconfont icon-xuanzhongduihaobeifen"></i>未上架</span>
                                    <!-- <span class="flex-1"><i class="iconfont icon-xingming-"></i>所有客户可见</span> -->
                                </div>
                                <div class="flex">
                                    <span>
                                        <i style="font-size:17px;" class="iconfont icon-yanjing"></i>{{item.design_market_n_visited}}次</span>
                                    <span v-if="item.published_at" class="flex-1">
                                        <i class="iconfont icon-shenhedengdai"></i>
                                        <span style="margin-right:5px">最后上架时间</span> {{item.published_at | moment("YYYY/MM/DD")}}</span>
                                </div>
                            </div>
                            <div class="item-avatar flex flex-align-center">
                                <div style="width:32px;height:32px;border-radius:50%;overflow:hidden;margin-right:8px;">
                                    <div v-if="item.designer && item.designer.avatar" class="bg_img"
                                        :style="{backgroundImage: 'url(' + imgUrl+item.designer.avatar + ')'}"></div>
                                    <img v-else class="bg_img" src="../../../assets/images/user.png">
                                </div>
                                <div v-if="item.designer && item.designer.name">{{item.designer.name}}</div>
                            </div>
                        </div>
                        <div class="lit-right flex-1 flex flex-align-center">
                            <div style="margin-bottom:35px;" class="price_percent">
                                <div>{{item.price_percent | percentFilt}}%</div>
                                <div>分成合作比例</div>
                            </div>
                            <div v-if="!item.factory || item.factory.stat<2">
                                <el-button v-if="item.stat<3 && item.stat==1 && item.factory_list && item.factory_list.ing.length==0" @click="changeSwitch(item,idx)" size="small" type="primary" plain>
                                    下架
                                </el-button>
                                <el-button v-if="item.stat<3 && item.stat==2" @click="changeSwitch(item,idx)" size="small" type="primary" plain>
                                    上架
                                </el-button>
                                <el-button v-if="item.stat==2" @click="edit(item)" size="small" type="primary" plain>编辑</el-button>
                                <el-button v-if="item.stat==2" @click="del(item)" size="small" type="danger" plain>删除</el-button>
                                <el-button v-if="item.stat==3" @click="undel(item)" size="small" type="danger" plain>恢复删除</el-button>
                            </div>
                        </div>
                    </div>
                    <div class="list-item-bottom">
                        <div>
                            <div v-if="item.factory_list.ing && item.factory_list.ing.length>0" class="item-facList">
                                <div v-for="(item1,idx1) in item.factory_list.ing" :key="idx1" class="item-avatar flex flex-align-center">
                                    <div class="flex-1 flex">
                                        <div style="width:20px;height:20px;border-radius:50%;overflow:hidden;margin-right:8px;">
                                            <div v-if="item1.logo" class="bg_img"
                                                :style="{backgroundImage: 'url(' + imgUrl+item1.logo + ')'}"></div>
                                            <div v-else class="bg_img"
                                                :style="{backgroundImage: 'url(' + staticUrl+'design/placeholder/zhanweilogo%402x.jpg!width_250px' + ')'}"></div>
                                        </div>
                                        <div v-if="item1.name">{{item1.name}}</div>
                                    </div>
                                    <div style="text-align:center;" class="flex-1">
                                        <span>{{item1.expire_at | moment('YYYY年MM月DD日')}}到期</span>
                                        <!-- <el-popover placement="top-start"
                                            trigger="hover" :content="(item1.expire_at | moment('YYYY年MM月DD日'))+'到期'">
                                            <i slot="reference" style="font-size:16px;color:#2BBAAE;margin-left:10px;cursor:pointer;" class="iconfont icon-bianzu1"></i>
                                        </el-popover> -->
                                    </div>
                                    <div style="text-align:right;font-size:18px;color:#EE1818;" class="flex-1">￥{{item1.sale_amount | priceFilt}}</div>
                                </div>
                            </div>
                            <div v-else>
                                <div style="text-align:center;font-size:32px;padding:10px 0;">暂无工厂合作</div>
                            </div>
                            <div style="margin-top:10px;" class="item-facList">
                                <div style="padding:12px 30px;background:#F5F5F5;box-shadow:0px 3px 6px rgba(0,0,0,0.16);" class="flex flex-align-center">
                                    <div class="flex-1">合作工厂数：{{item.factory_list.ing.length+item.factory_list.end.length}}</div>
                                    <div style="text-align:center;" class="flex-1">
                                        <el-button @click.stop="viewStop(item)" type="text">查看已终止合作的工厂</el-button>
                                    </div>
                                    <div style="text-align:right;font-size:18px;color:#EE1818;" class="flex-1">￥{{item.allPrice | priceFilt}}</div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div v-if="pageShow" style="padding:12px 55px;text-align:center;" class="pagination-wrap">
                <el-pagination background layout="prev, pager, next" @current-change="currentChange"
                :current-page.sync="queryOpt.page" :page-size="queryOpt.pagesize"
                :total="total"></el-pagination>
            </div>

        </div>
        

        <el-dialog :title="popTitle" :visible.sync="popVisible">
            <!-- <div class="pop-content">
                <div class="flex flex-align-center">
                    <div>投诉理由</div>
                    <div>
                        <el-radio-group v-model="Blacklist.reason_id">
                            <el-radio :label="1">对方未按约定付款</el-radio>
                            <el-radio :label="2">对方违约</el-radio>
                        </el-radio-group>
                    </div>
                </div>
                <div class="flex">
                    <div>描述</div>
                    <div>
                        <el-input type="textarea" :rows="3" placeholder="请输入内容"
                        v-model="Blacklist.desc"></el-input>
                    </div>
                </div>
            </div> -->
            <!-- <div style="text-align:center;border-top:1px solid #ccc;padding-top:25px;">
                <el-button style="margin-right:20px;" size="small" @click="popHide()">取消</el-button>
                <el-button v-if="popTitle == '加入黑名单'" size="small" type="warning" @click="popJr">加入黑名单</el-button>
                <el-button v-if="popTitle == '投诉工厂'" size="small" type="warning" @click="popTs">确认提交</el-button>
            </div> -->
            <ItemStop v-if="popTitle=='已终止合作的工厂'" :list="factoryEnd"></ItemStop>
        </el-dialog>
    </div>
</template>

<script>
    import _ from 'lodash';
    import { IMG_URL_PRE,STATIC_URL_PRE } from '@/config'
    import { listPercentProduct,togglePercentProduct,delPercentProduct,undelPercentProduct,addBlacklist,addComplain } from '@/service/design';
    import ItemStop from './ItemStop.vue';
    export default {
        components: {
            ItemStop,
        },
        data() {
            return {
                imgUrl: IMG_URL_PRE,
                staticUrl:STATIC_URL_PRE,
                popTitle:null,
                popVisible:false,
                queryOpt: {
                    pagesize: 10,
                    page: 1,
                    sort_by: 0,
                    sort_dir: 0,
                    cat_ids: [],
                },
                total:0,
                pageShow:false, // 强制刷新分页组件
                items: [],
                Blacklist:{
                    to_comp_id:null,
                    reason_id:1,
                    title:"对方未按约定付款",
                    desc:null,
                },
                factoryEnd:null,
                power:false, // 判断是否有权限
            }
        },
        created() {
            // this.loadItems();
            // this.debounceRefreshItems = _.debounce(this.loadItems, 500);
        },
        // watch: {
        //     queryOpt:{
        //         handler(){
        //             this.debounceRefreshItems();
        //         },
        //         deep: true
        //     }
        // },
        mounted() {
            if (this.$route.query.page && this.$route.query.page>1) {
                this.queryOpt.page=parseInt(this.$route.query.page);
                this.loadItems();
            } else {
                this.loadItems();
            }
            this.$nextTick(()=>{
                this.pageShow = true;
            })
        },
        methods: {
            goto(path){
                if (this.power) {
                    this.$message.error("没有权限");
                    return;
                }
                this.$router.push(path);
            },
            currentChange(e) {
                this.$router.push({
                    query:{ page:e }
                });
                this.loadItems();
            },
            loadItems() {
                var _this = this;
                this.items = [];
                const _loading = this.$loading({
                    target:this.$refs.load,
                    lock: true,
                    text: "拼命加载中....",
                });
                listPercentProduct(this.queryOpt).then(rst => {
                    console.log('listPercentProduct',rst);
                    this.total = rst.total;
                    this.items = rst.list;
                    this.items.forEach(function(e) {
                        _this.$set(e,"allPrice",0);
                    });
                    _loading.close();
                }).catch(err => {
                    _loading.close();
                    this.$message.error(err.message);
                    if (err.message == '没有权限') this.power = true;
                });
            },
            viewStop(item) {
                this.popTitle = "已终止合作的工厂";
                this.popVisible = true;
                this.factoryEnd = item.factory_list.end;
            },
            changeSwitch(item,index) {// 上下架
                let text=null,stat=null;
                if (item.stat==1) {
                    text = "下架"; stat = 2;
                } else if (item.stat==2) {
                    text = "上架"; stat = 1;
                }
                this.$confirm('确认是否'+text+'?', '温馨提示', { confirmButtonText: '确定',
                cancelButtonText: '取消', type: 'warning',
                }).then(() => {
                    togglePercentProduct({product_no:item.id,stat:stat}).then(()=>{
                        // this.loadItems();
                        this.items[index].stat = stat;
                        this.$message({ type: 'success', message: text+'成功！' });
                    }).catch(err => {
                        console.log(err);
                        this.$message.error(err.message);
                    });
                });
                console.log(item);
            },
            edit(item) {
                this.$router.push({
                    path:"/desgin/fchz/submit",
                    query:{id:item.id,}
                })
            },
            // 删除
            del(item) {
                this.$confirm('是否确认删除?', '温馨提示', { confirmButtonText: '确定',
                cancelButtonText: '取消', type: 'warning',
                }).then(() => {
                    delPercentProduct({product_no:item.id}).then(()=>{
                        this.loadItems();
                        this.$message({ type: 'success', message: '删除成功!' });
                    }).catch(err => {
                        console.log(err);
                        this.$message.error(err.message);
                    });
                });
            },
            // 恢复删除
            undel(item) {
                this.$confirm('是否确认恢复删除的内容?', '温馨提示', { confirmButtonText: '确定',
                cancelButtonText: '取消', type: 'warning',
                }).then(() => {
                    undelPercentProduct({product_no:item.id}).then(()=>{
                        this.loadItems();
                        this.$message({ type: 'success', message: '恢复成功!' });
                    }).catch(err => {
                        console.log(err);
                        this.$message.error(err.message);
                    });
                });
            },
            // 投诉工厂(加入黑名单)
            popTsShow(id,text) {
                this.popTitle = text;
                this.popVisible = true;
                this.Blacklist.to_comp_id = id;
            },
            popTs() {
                if (this.Blacklist.reason_id == 2) {
                    this.Blacklist.title="对方违约";
                }
                console.log(this.Blacklist);
                if (this.popTitle == '加入黑名单') {
                    addBlacklist(this.Blacklist).then(rst => {
                        this.loadItems();
                        this.popHide();
                        setTimeout(()=> {
                            this.$alert('您可以在“我的-工厂黑名单中管理已加入黑名单的工厂', 
                            '温馨提示', {confirmButtonText: '好的',
                            });
                        },300);
                    }).catch(err => {
                        this.$message.error(err.message);
                    });
                } else{
                    addComplain(this.Blacklist).then(rst => {
                        this.loadItems();
                        this.popHide();
                        setTimeout(()=> {
                            this.$alert('感谢您的反馈，我们会尽快核实您的信息。一旦属实，会对工厂采取必要措施，以便保护您的权益', 
                            '温馨提示', {confirmButtonText: '好的',
                            });
                        },300);
                    }).catch(err => {
                        this.$message.error(err.message);
                    });
                }
            },
            popHide() {
                this.popVisible = false;
            },
            
        }
    }
</script>
<style scoped src="../style.css"></style>
<style scoped>
    .list-item {
        box-shadow:0px 2px 4px rgba(0,0,0,0.16);
        font-size: 14px;
        color: #808080;
        position: relative;
        transition: all 0.2s;
    }
    .list-item:hover {
        box-shadow: 0px 6px 16px rgba(0,0,0,0.16);
    }
    .list-item:first-child {
        margin-top: 0;
    }
    .list-item .list-item-top {
        padding: 20px 30px;
        border-bottom:1px solid rgba(204,204,204,1);
    }
    /* .list-item .list-item-bottom {
        padding: 20px 30px;
    } */
    .lit-middle {
        min-width: 320px;
        padding-top: 19px;
    }
    .serie-name {
        display: inline-block;
        padding: 0 31px;
        height:21px;
        line-height: 21px;
        background:linear-gradient(90deg,rgba(251,199,190,1) 0%,rgba(254,165,166,1) 100%);
        border-radius:4px;
        font-size: 14px;
        color: #FFFFFF;
        letter-spacing: 5px;
        margin-bottom: 8px;
    }
    .item-name {
        font-size: 18px;
        color: #808080;
        height: 24px;
        line-height: 24px;
        margin-bottom: 8px;
    }
    .item-type>div {
        margin-bottom: 10px;
    }
    .item-type>div>span:first-child {
        width: 120px;
        /* margin-right: 40px; */
    }
    .item-type>div i {
        font-size: 18px;
        margin-right: 10px;
    }
    .lit-right {
        min-width: 200px;
        text-align: right;
        padding-top:15px;
        margin-right: 30px;
    }
    .price_percent {
        width:120px;
        height:120px;
        text-align: center;
        background:url('../../../assets/images/desgin/design_percent_bg.png') no-repeat center center;
        border-radius:50%;
        margin: 0 auto;
    }
    .price_percent>div:first-child {
        font-size: 30px;
        color: #FFFFFF;
        line-height: 42px;
        padding: 30px 0 5px 0;
    }
    .price_percent>div:last-child {
        font-size: 14px;
        color: #FFFFFF;
        line-height: 20px;
    }
    .item-facList>div {
        padding: 20px 30px;
    }
    .item-facList>div:nth-child(2n) {
        background: #F5F5F5;
    }
</style>
<style scoped>
    .block {
        color: rgba(41, 41, 41, 1);
        background: rgba(255, 255, 255, 1);
        overflow: auto;
        margin: 30px;
        padding: 30px;
    }

    .kpi {
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        color: rgba(77, 77, 77, 1);
        padding: 5px;
    }

    .divider {
        border: none;
        width: 2px;
        background: #eee;
        height: 80%;
    }

    .filter-items>* {
        margin: 0 10px;
        cursor: pointer;
    }

    .filter-items>*:hover {
        color: blue;
    }

    .filter-items>*.selected {
        color: blue;
    }

    .item:not(:last-child) {
        border-bottom: 1px solid rgba(216, 216, 216, 1);
        margin-bottom: 20px;
    }
    .sign-state{
        width: 80px;
        height: 80px;
        border-radius: 50%;
        border: 2px dashed #eee;
        text-align: center;
        line-height: 80px;
        display: inline-block;
    }
    .sign-state.wait{
        border-color: #009688;
        background: #00968840;
        color: #009688;
    }
    .sign-state.already{
        border-color: #fff;
        background: #FFC107;
        color: aliceblue;
    }
    .sign-state.ing{
        border-color: #519eff;
        background: #00bcd46b;
        color: #519eff;
    }
</style>
<style scoped>
    div>>> .el-table {
        border-top:1px solid #EBEEF5;
        border-left:1px solid #EBEEF5;
        border-right:1px solid #EBEEF5;
    }
    div>>> .el-table .cell {
        text-align: center;
    }
    div>>> .el-table .style-active .cell,div>>> .el-table .style-active .cell h3,div>>> .el-table .style-active .cell>* {
        color: #808080b7;
    }
    div>>> .el-table .el-button {
        margin-left: 10px;
        margin-bottom: 10px;
    }
    div>>> .el-table .style-active .cell button.el-button {
        color: #4D4D4D;
    }
    .left-coverImg {
        padding-right: 20px;
    }
    .left-coverImg img{
        width: 120px;
        height: 120px;
    }
    /* 弹框 */
    /* div>>> .el-dialog {
        max-width: 500px;
    } */
    .pop-content {
        padding: 20px 40px 0 40px;
    }
    .pop-content>div{
        margin-bottom: 30px;
    }
    .pop-content>div>div:first-child {
        width: 80px;
        text-align: right;
        margin-right: 20px;
    }
</style>