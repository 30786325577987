<template>
    <div>
        <el-table :data="list" style="width: 100%">
            <el-table-column prop="name" label="已终止合作的工厂">
            </el-table-column>
            <el-table-column prop="end_at" label="终止日期">
                <template slot-scope="scope">
                    <div>{{scope.row.end_at | moment("YYYY.MM.DD")}}</div>
                </template>
            </el-table-column>
            <el-table-column prop="sale_amount" label="历史获得收益">
                <template slot-scope="scope">
                    <div style="font-size:14px;color:#F66F6A;">￥ {{scope.row.sale_amount | priceFilt}}</div>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
    export default {
        props:['list'],
        data() {
            return {
                
            }
        },
        watch: {
            
        },
        created() {
            console.log(this.list);
        },
        mounted() {},
        methods: {
            
        }
    }
</script>
<style scoped>
    
</style>